<template>
  <div>
    <a-select
      v-model="value"
      :placeholder="placeholder"
      allowClear
      show-search
      :disabled="disabled"
      :filter-option="false"
      :not-found-content="loading ? undefined : $t('暂无数据')"
      style="width: 100%"
      :dropdownMatchSelectWidth="false"
      @search="search"
      @change="change"
      @focus="focus"
      @popupScroll="scroll"
      :size="size"
    >
      <div slot="notFoundContent" style="text-align: center">
        <a-spin size="small" :spinning="loading" />
      </div>

      <a-select-option v-for="item in items" :key="item.id" :value="item.id">
        {{ item.number }} | {{ $t("库存") }} {{ item.remain_quantity }}
        <span v-if="item.production_date"> | {{ $t("生产日期") }}: {{ item.production_date }}</span>
      </a-select-option>

      <a-select-option v-if="!isloadedAll" key="loading" value="loading" disabled>
        <div style="text-align: center; height: 24px">
          <a-spin size="small" :spinning="loading" />
        </div>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { batchOption } from "@/api/option";

export default {
  props: ["value", "placeholder", "disabled", "size", "defaultItem", "warehouse", "material"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      items: [],
      searchForm: { search: "", page: 1, page_size: 16, has_stock: true },
      totalRows: 0,
      loading: false,
      timeout: null,
    };
  },
  computed: {
    options() {
      let items = [...this.items];
      if (this.defaultItem && !this.loading && (!this.searchForm.search || this.searchForm.search == "")) {
        if (this.defaultItem.batch && this.defaultItem.batch_number) {
          if (this.items.findIndex((item) => item.id == this.value) == -1) {
            items.splice(0, 0, { id: this.defaultItem.batch, number: this.defaultItem.batch_number });
          }
        }
      }
      return items;
    },
    isloadedAll() {
      return this.searchForm.page * this.searchForm.page_size >= this.totalRows;
    },
  },
  methods: {
    list() {
      if (this.searchForm.page === 1) {
        this.items = [];
      }

      this.loading = true;
      let searchForm = { ...this.searchForm, warehouse: this.warehouse, material: this.material };
      batchOption(searchForm)
        .then((data) => {
          this.totalRows = data.count;
          this.items.push(...data.results);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change(value) {
      this.$emit("change", value);
    },
    focus() {
      this.searchForm.page = 1;
      this.list();
    },
    scroll({ target }) {
      if (!this.loading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
        if (!this.isloadedAll) {
          this.searchForm.page += 1;
          this.list();
        }
      }
    },
    search(value) {
      this.searchForm.page = 1;
      this.searchForm.search = value;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(this.list, 300);
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
